.task_panel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;   
    overflow-wrap: break-word;
    width: 100%;
    
}

.task_add_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin: auto;
}
.textarea_task {
    border-bottom: 1px solid #ccc;
}

.textarea_task:focus {
    border-bottom: 1px solid rgb(179, 179, 179);
    outline: none;
}
.add_btn {
    color: #242424;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    margin-top: 20px;
    background-color: rgba(255, 255, 255, 0);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    padding: 15px 30px;
    transition: 150ms;
    text-transform: uppercase;
    &:hover {
        cursor: pointer;
        background-color: rgb(233, 233, 233);
    }

    &:active {
        background-color: #aaa;
    }

}