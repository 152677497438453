.item_list {
    width: 100%;
    word-wrap: break-word;
    max-height: calc(100vh - 370px);
    min-height: 190px;
    overflow: auto;
    ul {
        padding: 0;
        width: 100%;
        min-height: 100px;
        display: flex;
        flex-direction: column;
        color: #242424;
    }
    
}
.empty_title {
    color: #ccc;
    margin: auto;
}
