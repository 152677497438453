@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@mixin main-font {
    font-family: 'Roboto', sans-serif;
}
* {
    box-sizing: border-box;
}
body {
    margin: 0;
    @include main-font();
    color: #242424;
}
li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

button, input {
    border: none; outline: none;
    @include main-font();
}
.app {
    margin: auto;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    width: 420px;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 450px){
    .app {
        width: 290px;
    }
}

