
.done {
    transition-duration: 900ms;
    text-decoration: line-through;
    color: rgba(53, 53, 53, 0.534);
    font-family: 'Roboto', sans-serif;
    font-weight: light;
}


.date {
    font-size: 10px;
    color: rgb(87, 87, 87);
}
.item_task {
    margin-top: 10px;
    font-family: 'Roboto', sans-serif;
    
}
.important {
    transition-duration: 300ms;
    color: rgb(49, 121, 168);
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}


.item_container {
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    padding: 15px;
    margin-bottom: 5px;
}

.btn_flag_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;
    margin-top: 5px;
}

.important_btn, .delete_btn, .done_btn {
    margin: 5px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    padding: 2px 8px;
}
.important_btn {
    margin-left: 0;
    background-color: rgb(65, 151, 190);

    
}
.delete_btn {
    background-color: rgb(180, 76, 76);
}
.done_btn {
    background-color: rgb(73, 161, 85);
}
.important_btn:active, .delete_btn:active, .done_btn:active {
    transition-duration: 200ms;
    box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.288);
}