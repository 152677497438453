.fitler_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
}

.input_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

}

.filter {
height: 60px;
}
.filter:focus {
    outline: none;
}
.btn_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 10px;
    position: relative;
    height: 48px;
    width: 100%;
    background-color: #fff;
    white-space: nowrap;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
}
.btn_filter {
    padding: 0 30px;
    border-bottom: 2px solid rgb(255, 255, 255);
    background-color: #fff;
    &:hover {
        border-bottom: 2px solid rgb(233, 233, 233);
        background-color: rgb(223, 223, 223);
        cursor: pointer;
    }
    &:active, &.active {
        border-bottom: 2px solid rgb(255, 134, 134);
    }
}

@media screen and (max-width: 450px) {
    .btn_filter {
        padding: 0 8px;
    }
}

.input_container {
    position: relative;
    svg {
        position: absolute;
        right: 0;
        fill: #242424;
    }
    input {
        border-bottom: 1px solid rgb(233, 233, 233);
    }
}